/*jshint esversion: 6 */

import React, {Component} from 'react';
import NavComponent from './NavComponent';
//import Carousel from './CarouselComponent';

class ImageViewer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            galleries :[],
            photos : [],
            // photo: this.props.photos[0],
            photoindex : 0,
            slideShowTimer: null,
            autoSlideText : "Play",
            galid: this.props.match.params.id,
            hasPhotos: true,
            msg : ""
        };

        //this.photoindex = this.setPhotoIndex.bind(this);
        //this.nextIndex = this.nextIndex.bind(this);
        //this.autoSlideText = this.autoSlideText.bind(this);
        
    }
    componentDidMount(){
        //console.log("hit image viewer - didmount");

        // var galleryCount =0;

 this.setState({msg:<p className="text-center ptm">Loading</p>})
//         this.props.getgalleries()
//         .then(res => this.setState({ galleries : res.express }))

        //.then(res => res.express.length > 0 ? this.setState({ galleries : res.express }) : this.setState({msg : "Sorry no such gallery, please try a gallery from the menu"}))
        //.catch(err => console.log(err));

        this.imageViewerInit();
    }

    // static getDerivedStateFromProps(nextProps, prevState){
    //     if(nextProps.id !== prevState.id){
    //       return { galid: nextProps.id};
    //    }
    //    else return null;
    //  }

    // componentDidUpdate(prevProps, prevState){
    //     console.log("hit image viewer - DidUpdate");
    //     if(prevProps.id !== this.props.id){
    //         //Perform some operation here
    //         this.setState({someState: someValue});
    //         this.classMethod();
    //       }
    // }

    componentWillReceiveProps(nextProps){
        //console.log( "This id = " + this.props.match.params.id + ", Next id = " + nextProps.match.params.id );
        //console.log("hit image viewer - ReceiveProps");
        if(nextProps.match.params.id !== this.props.match.params.id){           
            this.setState({
                galid: nextProps.match.params.id,
                photoindex : 0,
                msg:<p className="text-center ptm">Loading</p>
            });
            //console.log("received props : ", nextProps.match.params.id)
            this.getPhotos(nextProps.match.params.id)
            //this.imageViewerInit();
        }

        
    }

    getPhotos(galid){
        // this.setState({msg : <p className="text-center">Loading page</p>})

        
        //     this.props.getphotosbygalid(galid)
        //      //.then(res => this.setState({ photos : res.express}))
        //     .then(res => res.express !== null && typeof res.express !== 'undefined' && res.express.length > 0 ? 
        //         this.setState({ photos : res.express, msg : ""}) : 
        //         this.setState({photos : res.express, msg : <p className="text-center">Sorry no images</p>}))
        //     .catch((err) => {
        //         //res => console.log("No gallery found")               
        //         this.setState({msg : <p className="text-center">Sorry no such gallery</p>})
        //         //throw new Error('Error ' + err.message)                         
                
        //     })

        this.props.getphotosbygalid(galid)        
        .then(
            (res) => {              
                if(typeof res !== 'undefined'){
                    if(typeof res.express !== 'undefined' && res.express.length === 0 ){                            
                        this.setState({msg : <p className="text-center ptm">Sorry no such gallery, please try a gallery from the menu</p>})
                    }else{
                        this.setState({ photos : res.express, msg : ""})
                    }
                }

                if(typeof res === 'undefined'){
                    this.setState({msg : <p className="text-center ptm">Sorry no such gallery, please try a gallery from the menu</p>})
                }
            }
        )
            
                
                
        //.catch(err => console.log(err));
        
        
    }

    imageViewerInit(){ 
        //console.log(this.props.match.params.id)     
        var galid = "1";
        if(this.props.match.params.id !== undefined){
            galid = this.props.match.params.id;
        }
        this.getPhotos(galid) 
    }

    nextThumb(){  
        var newstate = this.state.photoindex < this.state.photos.length -1 ? this.state.photoindex +1 : 0;
        this.setState({photoindex: newstate});
        

        // this.setState(            
        //     {photoindex: this.state.photoindex < this.state.photos.length -1 ? this.state.photoindex +1 : this.state.photos.photoindex = 0}, function(){
        //         // console.log(this.state.photoindex, this.state.photos.length);
        //     }
        // );        
    }

    prevThumb(){  
        var newState =  this.state.photoindex > 0 ? this.state.photoindex -1 : this.state.photos.length -1;
        this.setState({photoindex: newState});
        
        // this.setState(
        //     {photoindex: this.state.photoindex > 0 ? this.state.photoindex -1 : this.state.photoindex = (this.state.photos.length -1)}, function(){
        //         //console.log(this.state.photoindex, this.state.photos.length);
        //     }
        // );
    }

    selectThumb(selectedIndex){
        this.setState({photoindex: selectedIndex});
    }
    
    autoSlideShow(e){
        //console.log(this.state.photoindex, this.state.photos.length);  

        if(this.state.autoSlideText === "Play"){
            this.setState({autoSlideText: "Stop"});

            this.slideShowTimer = setInterval(() => {
                //var newTimerState = this.state.photoindex < this.state.photos.length -1 ? this.state.photoindex +1 : 0;

                this.setState(            
                    {photoindex: this.state.photoindex < this.state.photos.length -1 ? this.state.photoindex +1 : 0});
            }, 3000);
        }else{
            this.setState({autoSlideText: "Play"});
            clearInterval(this.slideShowTimer);
        }                      
    }

    stopSlideShow(){
        clearInterval(this.slideShowTimer);
    }    

    render(){
        return(
            <div>  

<div className="main-container">
            <div id="slidearea" className="">
                <div className="mobile nextprev">
                    <div className="left prevgrid"><button className="previous arrow" onClick={() => this.prevThumb()}><div className="leftarrow"></div>Previous</button></div>
                    <div className="size1of4 left"><button className="play" onClick={() => this.autoSlideShow()}>{this.state.autoSlideText}</button></div>
                    <div className="left nextgrid"><button className="next" onClick={() => this.nextThumb()}>Next <div className="rightarrow"></div></button></div>
                </div>
                <div className="leftcolumn left size1of5">
                    <NavComponent getgalleries={this.props.allGalleries} getphotosbygalid={this.props.getPhotosByGalleryId} galid={this.state.galid}></NavComponent>    
                    {/* <NavComponent getgalleries={this.props.callBackendAPI} ></NavComponent> */}
                        
                </div>
                        {/* {this.state.msg !== ""  ? (
                            <p className="text-center">{this.state.msg}</p>
                        ) : ("")} */}

                       

                       <div id="photoframe" className="">
                       
                       {this.state.photos.length > 0 ? (
                            <article>                           
                                {this.state.photos.map((item, index) =>{
                                    return(
                                        <div className={`image-${index}` + (this.state.photoindex === index ? " currentslide" : " hidden")} data-slide={item._id} key={item._id}>
                                            <div className="main-image" >
                                                <img className="image display block centerblock" alt={`${item.description}`} src={require(`./../images/main/${item.file_name}`)} />
                                            </div>
                                        </div>
                                    )
                                })} 
                                
                            </article>
                            ) : ("")}



                            
                            
                        </div> 
                        
                        {/* <div className="rightcolumn left">
                            <button id="next" className="nextslide next hidden left"><span className="offscreen">Next</span></button>
                        </div> */}
                        
            </div>
        </div>

                

                <div id="floor" className="">
                    <div id="ground">

                        {/* <Carousel photos={this.state.photos} setMainPhoto={this.setMainPhoto()} photoindex={this.state.photoindex} ></Carousel> */}
                        {this.state.photos.length > 0 ?(
                        <div id="carousel" className="">

                            <button id="gallery-prev" onClick={() => this.prevThumb()}><img src={require("./../images/icons/prevarrow.png")} alt="" /><span className="offscreen">previous</span></button>
                            <div id="gallery-wrap" className="left"> 
                                  
                                <nav className="placesnav">
                                    <h3 className="ostrichmedium">Galleries</h3>
                
                                    <ul id="gallery" style={{ transform : `translateX(-${this.state.photoindex*(100/this.state.photos.length)}%)`}}>
        
                                    {this.state.photos.map((item, index) =>{
                                        return(
                                            
                                                <li className={`thumb left mrm active-slide-${this.state.photoindex} ` + (this.state.photoindex === index ? "current" : "")} key={item._id} >
                                                    <div onClick={() => this.selectThumb(index)}><img alt={`${item.description}`} src={require(`./../images/thumb/${item.file_name}`)} /></div>                                                                          
                                                </li>
                                        
                                        )
                                    })} 

                                    </ul>
                                </nav>                                 
                            </div>
                            <button id="gallery-next" onClick={() => this.nextThumb()}><img src={require("./../images/icons/nextarrow.png")} alt="next button"/><span className="offscreen">Next</span></button>
                            <button id="autoslide" onClick={() => this.autoSlideShow()} className="text-center j-autoslide displayblock">{this.state.autoSlideText}</button>                            
                        </div>
                        ) : ("")}
                        {this.state.msg}  
                        
                    </div>
                </div>         
            </div>
        )
    }
}

export default ImageViewer