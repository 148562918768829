/*jshint esversion: 6 */

import React, { Component } from 'react';
/*import NavComponent from './components/NavComponent';*/
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import ImageViewer from './components/ImageViewer';
/*import Gallery from './components/GalleryComponent';*/

import './App.css';
//import { EPROTONOSUPPORT } from 'constants';



class App extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            galleries :[],
            photos : [],
            showMobileMenu : false,
            msg : ""
            //id : this.props.match.params.id
            //galid : this.props.match.params.id
        }
        
        //this.getGalleries = this.getGalleries.bind(this)
    }    

    componentDidMount(){
      //this.setState({msg:<p className="text-center ptm">Loading</p>})
        this.getAllGalleries()
      .then(res => this.setState({ galleries : res.express, msg: "" }))
      .catch(err => console.log(err));
      

      
    //     this.getPhotosByGalleryId("1")
    //     .then(res => this.setState({ photos : res.express}))
    //     .catch(err => console.log(err));
    // this.callBackendAPI()
    // .then(res => this.setState({ galleries : res.express }))
    // .catch(err => console.log(err));

    }


  getAllGalleries = async() =>{
    const response = await fetch('/getgalleries');    
    
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message) 
    }
    //console.log(body.express[0].title)
    return body;
  }

  getPhotosByGalleryId = async(gal_id) =>{    
    //temp fix look at line 68 of ImageViewer
    // if(typeof gal_id === 'undefined'){

    //   gal_id = 1
    // }

    // if(isNaN(gal_id)){
    //   return null;
    // }
    const photos = await fetch('/getphotos/' + gal_id)
    //.then(body => photos.json())
    //.then(res => return res)

    // if(photos.status === 404){
    //   this.setState({errMsg : "Sorry no such gallery, please try a gallery from the menu"})
    // }
    //console.log(photos)

    if(photos.status === 200){
      const body = await photos.json();
    
      // if(body.express.length === 0){
      //   this.setState({msg : "Sorry no such gallery, please try a gallery from the menu"})
      //   //throw Error("Sorry no such gallery, please try a gallery from the menu")

      //   console.log("length === 0")

      // }

      // if(body.express.length > 0){
      //   //this.setState({errMsg : ""});
      //   console.log("length > 0")
      // }
      return body;
    }
    else{
      //return photos
    }
  }

  toggleMenu(e){
    //e.preventDefault();
    
    if(this.state.showMobileMenu === true){
      this.setState({showMobileMenu : false})
    }else{
      this.setState({showMobileMenu:true})
    }
  }

  render() {
      //const {galleries} = this.state.galleries
    return (
        <BrowserRouter>
      <div className="App">
        <header>
            <div className="banner overflow-hidden">
                <div className="nameblock">
                    <h1><span className="ostrichblack">Mike</span> <span className="ostrichmedium">Fisher</span></h1>
                </div>
                <h1 className="ostrichmedium left size1of3">Photography</h1>
                <h4 className="right mobilemenulink" onClick={() => this.toggleMenu()}>Galleries</h4>
                
                <nav>  
                  
                {this.state.showMobileMenu ? (                  
                    <ul className="ostrichmedium right mln slidedown m-gal-menu">                        
                        {/* <li className="m-gal-menu">Galleries</li> */}
                          {this.state.galleries.map(item =>{
                            return(
                                <li className="m-gal-menu" key={item.gallery_id}>
                                    <Link to={`/${item.gallery_id}`} onClick={() => this.toggleMenu()} >{item.title}</Link>
                                </li>
                            )
                        })}   
                    </ul>
                    ) :(null)}   
                </nav>   
                         
                
                <ul className="mobile-menu hidden">
                    <li>All</li>
                    {this.state.galleries.map(item =>{
                            return(
                                <li key={item.gallery_id}>
                                    {item.title}
                                </li>)
                    })}
                    
                </ul>                
            </div>
        </header>
        
        {/* <ImageViewer photos={this.state.photos} getgalleries={this.state.galleries}></ImageViewer>*/}                   
        {/* <Route exact path="/" component={ImageViewer} photos={this.state.photos} getgalleries={this.state.galleries} /> */}
        {/* <Route path="/:id" component={ImageViewer} photos={this.state.photos} getgalleries={this.state.galleries} /> */}
        {/* <Route exact path="/" render={() => <ImageViewer photos={this.state.photos} getgalleries={this.state.galleries}></ImageViewer>} />
        <Route exact path="/:id" render={() => <ImageViewer photos={this.state.photos} getgalleries={this.state.galleries}></ImageViewer>} /> */}
        {/* <Route path='/:id' render={(props) => <Gallery {...props} photos={this.state.photos} getgalleries={this.state.galleries} ></Gallery>} /> */}

        {/* <Route exact path="/" render={props => <ImageViewer {...props} photos={this.state.photos} getgalleries={this.state.galleries} />} />
        <Route path="/:id" render={props => <ImageViewer {...props} photos={this.state.photos} getgalleries={this.state.galleries} />} /> */}
        <Switch>
        <Route exact path="/" render={props => <ImageViewer {...props} getphotosbygalid={this.getPhotosByGalleryId} getgalleries={this.getAllGalleries } allGalleries={this.state.galleries} errMsg={this.state.errMsg !== ""? (<p>{this.state.errMsg}</p>) : ("")} />} />
        <Route path="/:id" render={props => <ImageViewer {...props} getphotosbygalid={this.getPhotosByGalleryId} getgalleries={this.getAllGalleries } allGalleries={this.state.galleries} errMsg={this.state.errMsg !== ""? (<p>{this.state.errMsg}</p>) : ("")} />} />
        </Switch>
        
             
        <footer>                  
            <p className="text-center">&copy; Mike Fisher {new Date().getFullYear()}</p>               
        </footer>
        
      </div>


      </BrowserRouter>
    );
    
  }
}

export default App;
//export default ({match: {url}}) => App;