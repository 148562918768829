/*jshint esversion: 6 */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import "./css/normalize.min.css";
import "./css/core.css";
import "./css/mobile.css";

/* import "./scripts/jquery-1.9.0.min.js";
import "./scripts/modernizr-2.6.2-respond-1.1.0.min.js";
import "./scripts/main.js"; */


import App from './App';
import * as serviceWorker from './serviceWorker';
/*import {BrowserRouter} from 'react-router-dom';*/
//import { BrowserRouter as Router, Route, Link } from "react-router-dom";

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(

<App />

, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
