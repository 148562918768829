/*jshint esversion: 6 */
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
/*import ImageViewer from './ImageViewer';*/


class Nav extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            galleries:[]
        }
    }
    componentWillMount(){
        // get galleries
    }
    componentDidUpdate(){
        //console.log("hit update");
        //this.props.getphotosbygalid(this.props.galid);
    }

    // navClick(galid){        
    //     console.log("id = " + galid);        
    //     this.props.getphotosbygalid(galid);
    // }

    render(){
        return(            
            <div>
                <nav className="placesnav size2of3">
                    <h3 className="ostrichmedium">Galleries</h3>
                    <ul className="left">                               
                        {/* <li><Link to={`/`} onClick={() => this.navClick(1)}>All</Link></li> */}
                            {this.props.getgalleries.map(item =>{
                                return(
                                    <li key={item.gallery_id}>
                                        {/* <a href={`/${item.gallery_id}`}>{item.title}</a> */}
                                        <Link to={`/${item.gallery_id}`} >{item.title}</Link>
                                    </li>
                                )
                            })}
                    </ul>
                </nav>       
            </div>
        )
    }
}

export default Nav